//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
}

.fw-300 {
    font-weight:300 !important;
}

.fw-400 {
    font-weight:400 !important;
}

.fw-700 {
    font-weight:700 !important;
}

.fw-800 {
    font-weight:800 !important;
}

.kavel-card {
    padding:2px;
    padding-right:15px;
    color: $darkblue;
    line-height: 17px;
}

.kavel-card:hover {
    background:$grey;
}

.kavel-card a {
    text-decoration: none;
}

.kavel-card strong {
    font-size:14px;
}

.kavel-card span {
    font-size:11px;
}

.kavel-premium-card {
    background: $darkblue;
    padding:2px;
    padding-right:15px;
    color: $white;
}

.kavel-premium-card:hover {
    background:$blue;
}

.kavel-premium-card a {
    text-decoration: none;
    color: $white;
}


.kavel-premium-card strong {
    font-size:18px;
}

.kavel-premium-card span {
    font-size:12px;
}


.nieuws-card {
    padding:2px;
    padding-right:15px;
    color: $darkblue;
    line-height: 17px;
    border:none;
}

.nieuws-card:hover {
    background:$grey;
}

.nieuws-card a {
    text-decoration: none;
}

.nieuws-card strong {
    font-size:14px;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
